import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Notice } from 'hudl-uniform-ui-components';
import noticePreviewData from '../../../../data/previews/notice.yml';
import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noticeType: false,
      styleType: false,
    };
  }

  handleTypeChange = type => {
    this.setState({
      noticeType: type,
    });
  };
  handleStyleChange = style => {
    this.setState({
      styleType: style,
    });
  };

  render() {
    let action = null;

    if (this.state.noticeType === 'warning') {
      action = { text: 'Upgrade Account' };
    } else if (this.state.noticeType === 'critical') {
      action = { text: 'Upload Video' };
    } else if (this.state.noticeType === 'confirmation') {
      action = { text: 'Upload Video' };
    }

    let actionStyle = null;
    if (this.state.styleType === 'minimal') {
      actionStyle = { text: 'Upgrade Account' };
    }
    return (
      <PageWithSubNav
        pageType="design"
        title="Notice"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Notifications"
          tierThree="Notice"
        />
        <Paragraph>
          If the information does pertain to just one section, use a{' '}
          <Link href="/components/notifications/note" isDesignCodeLink>
            note
          </Link>
          . (And for immediate feedback as the result of a workflow, go with a{' '}
          <Link href="/components/notifications/toast" isDesignCodeLink>
            toast
          </Link>
          .)
        </Paragraph>
        <PageNavigation>
          <PageNavigationLink>Type</PageNavigationLink>
          <PageNavigationLink>State</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Type">
          <Paragraph>
            All page notices are displayed in line, fixed to the top of the
            primary content area of the given page.
          </Paragraph>
          <ComponentPreview
            name="NoticeType"
            layout="fullWidth"
            previewData={noticePreviewData.types}
            onChange={this.handleTypeChange}>
            <Notice text="Standard" title="" message="" action={action} />
          </ComponentPreview>
        </Section>

        <Section title="Style">
          <Paragraph>
            Page Notices have two different styles, standard and minimal.
          </Paragraph>
          <ComponentPreview
            name="NoticeStyle"
            layout="fullWidth"
            onChange={this.handleStyleChange}
            previewData={noticePreviewData.styles}>
            <Notice action={actionStyle} />
          </ComponentPreview>
        </Section>

        <Section title="State">
          <Paragraph>
            All notices appear as soon as the page loads. They should remain
            dismissable either with an actual dismiss or by completing the
            action listed. All notices (except for critical) should{' '}
            <em>permanently</em> disappear once they’ve been dismissed.
          </Paragraph>

          <Paragraph>
            Critical notices will disappear when dismissed, but only for that
            session. If the action has not been completed, the notice will
            reappear on the next page load.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="force notices to persist without the ability to dismiss."
              img="notice-dismiss-dont"
            />
            <DontDoItem
              type="do"
              text="include a way for users to remove the notice."
              img="notice-dismiss-do"
            />
          </DontDo>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Space is of a premium when designing notices for mobile devices.
            Stick to only using the minimal style.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            Every critical notice should include an action to help the user
            solve their issue. The action itself is optional on all other
            notices. Just be sure to weigh the value it may (or may not) add.
          </Paragraph>

          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            A notice is always positioned beneath the navbar within the primary
            content area of the given page. It should span the full width of the
            page until the natural max-width is achieved.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="position a page notice above a sidebar or side panel."
              img="notice-position-dont"
            />
            <DontDoItem
              type="do"
              text="keep a notice in line with the primary content area."
              img="notice-position-do"
            />
          </DontDo>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Case & Punctuation</SectionSubhead>
          <Paragraph>
            Notices follow our{' '}
            <Link href="/words/content-elements/labels-non-label-text">
              non-label text
            </Link>{' '}
            guidelines—use sentence case with appropriate punctuation.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use crazy punctuation to level up the message."
              img="notice-punctuation-dont"
            />
            <DontDoItem
              type="do"
              text="use complete sentences followed by periods."
              img="notice-punctuation-do"
            />
          </DontDo>

          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            Make the content readable and don’t go overboard—just be sure it’s
            longer than the title. Keep it brief with the most important info at
            the front.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="overwhelm the user with unnecessary information."
              img="notice-contentlength-dont"
            />
            <DontDoItem
              type="do"
              text="keep the message short and to the point."
              img="notice-contentlength-do"
            />
          </DontDo>

          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Titles should be concise with keywords at the front. The content
            should relate directly to the action they’d need to take.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="explain the entire message in the title."
              img="notice-title-dont"
            />
            <DontDoItem
              type="do"
              text="focus on the most important info."
              img="notice-title-do"
            />
          </DontDo>

          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            Try limiting actions to a single verb. If you can’t, stick to a
            three-word phrase.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="make button labels complete sentences."
              img="notice-actions-dont"
            />
            <DontDoItem
              type="do"
              text="keep things simple with a single verb."
              img="notice-actions-do"
            />
          </DontDo>
        </Section>

        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
